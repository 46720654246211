@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noticia+Text:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    box-sizing: border-box;
    scroll-behavior: smooth;
}

@layer components {
    .flex-row-start {
        @apply flex flex-row w-full justify-between;
    }
    .primary-palette {
        @apply bg-primary text-white;
    }
    .btn-secondary {
        @apply bg-white text-primary p-2 rounded-md font-bold;
    }
    .btn-primary {
        @apply text-white bg-primary p-2 rounded-md font-bold;
    }
    section {
        @apply w-full flex flex-col p-4;
    }
    .heading {
        @apply text-3xl font-bold;
    }
    .input-field {
        @apply w-full flex flex-1 p-2 rounded-md border-2 border-gray-300 focus:outline-none focus:border-primary disabled:text-gray-400;
    }
    .checkbox {
        @apply w-4 h-4 border-2 border-gray-300 rounded-md focus:outline-none focus:border-primary order-1 ml-2 self-center;
    }
    .flex-col-4 {
        @apply flex flex-col gap-4;
    }
    .error {
        @apply text-red-500 text-sm flex gap-2 items-center;
    }
    .active {
        @apply bg-primary rounded-full p-2 text-white;
    }
    .sublink-active {
        @apply bg-purple-700;
    }
    table {
        @apply table-fixed w-full border-collapse border-spacing-4;
    }
    th {
        @apply text-primary;
    }
    tr {
        @apply border-b border-collapse border-spacing-4 text-left;
    }
    td, th {
        @apply p-2;
    }
    input {
        @apply w-full;
    }
    .modal {
        @apply fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50;
    }
    .modal-content {
        @apply bg-white w-11/12 md:w-1/2 lg:w-2/3 rounded-md shadow-lg max-h-[80vh] overflow-y-auto;
    }
    .modal-header, .modal-footer, .modal-body{
        @apply p-4;
    }
    .modal-body {
        @apply py-4;
    }
    .text-muted {
        @apply text-gray-500;
    }
    .rounded-btn {
        @apply bg-primary text-white font-bold p-4 uppercase hover:shadow-md active:opacity-75 transition-all rounded-full shadow-md
    }
}
